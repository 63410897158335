import React, { PropsWithChildren } from 'react';

function Workspace(props: PropsWithChildren) {
  return (
    <div className='flex items-center justify-center'>
      <div className='sm:w-[22.5rem] max-w-[45rem] md:w-full min-h-screen'>{props.children}</div>
    </div>
  );
}

export default Workspace;

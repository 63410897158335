import { AppProps } from 'next/app';
import 'public/style/index.css';
import CommonLayout from 'src/components/layout/common-layout';
import { ToastContainer, ToastProvider } from 'src/components/toast/toast';
import { AuthProvider } from 'src/lib/ap-module/react/auth-context';

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ToastProvider>
      <AuthProvider>
        <CommonLayout>
          <Component {...pageProps} />
          <ToastContainer />
        </CommonLayout>
      </AuthProvider>
    </ToastProvider>
  );
}

/**
 * Collection name
 */
const COLLECTION_NAME = {
  OBJECT: "object",
  ACTIVITY: "activity",
  PROFILE: "profile",
  PLACE: "place",
};

export default COLLECTION_NAME;

import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface HeadingProps extends WithClassName<PropsWithChildren> {
  size?: 'h1' | 'h2' | 'h3' | 'h4';
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span' | 'div';
  bold?: 'bold' | 'medium' | 'regular' | 'semibold' | 'light';
}

interface BodyProps extends WithClassName<PropsWithChildren> {
  size?: 'b1' | 'b2' | 'b3';
  element?: 'p' | 'span' | 'div';
  bold?: 'bold' | 'medium' | 'regular' | 'semibold' | 'light';
}

interface CaptionProps extends WithClassName<PropsWithChildren> {
  element?: 'p' | 'span' | 'div' | 'caption';
  bold?: 'bold' | 'medium' | 'regular' | 'semibold' | 'light';
}

const sizes = {
  h1: 'text-3xl leading-h1 tracking-suit',
  h2: 'text-2xl leading-h2 tracking-suit',
  h3: 'text-xl leading-h3 tracking-suit',
  h4: 'text-lg leading-h4 tracking-suit',
  b1: 'text-base leading-b1 tracking-suit',
  b2: 'text-sm leading-b1 tracking-suit',
  b3: 'text-xs leading-b1 tracking-suit',
  caption: 'text-2xs leading-caption tracking-suit',
};

const bolds = {
  bold: 'font-bold',
  medium: 'font-medium',
  regular: 'font-regular',
  semibold: 'font-semibold',
  light: 'font-light',
};

function Heading(props: HeadingProps) {
  const { size = 'h1', element = 'h1', bold = 'bold', children, className, ...rest } = props;

  const TagName = element;

  const twclassName = classNames(className, sizes[size], bolds[bold]);

  return (
    <TagName className={twclassName} {...rest}>
      {children}
    </TagName>
  );
}

function Body(props: BodyProps) {
  const { size = 'b2', element = 'p', bold = 'regular', children, className, ...rest } = props;

  const TagName = element;

  const twclassName = classNames(className, sizes[size], bolds[bold]);

  return (
    <TagName className={twclassName} {...rest}>
      {children}
    </TagName>
  );
}

function Caption(props: CaptionProps) {
  const { element = 'caption', children, bold = 'light', className, ...rest } = props;

  const TagName = element;

  const size = 'caption';

  const twclassName = classNames(className, sizes[size], bolds[bold]);

  return (
    <TagName className={twclassName} {...rest}>
      {children}
    </TagName>
  );
}

export default {
  Heading,
  Body,
  Caption,
};
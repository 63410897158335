import Logo from 'public/icon/Logo.svg';
import Divider from '../divider';

export default function Footer() {
  return (
    <>
      <div className='w-full px-4 py-6 overflow-hidden bg-gray-100 h-202'>
        <div className='flex flex-col items-start justify-start gap-4 left-4 top-6'>
          <img src={Logo.src} alt='Locallink logo' width={93} height={28} />

          <div className='flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 gap-2'>
            <div className='flex items-start justify-start flex-grow-0 flex-shrink-0 gap-1 '>
              <p className='flex-grow-0 flex-shrink-0 text-left text-gray-600 text-2xs'>이용약관</p>
              <p className='flex-grow-0 flex-shrink-0 text-left text-gray-600 text-2xs'>∙</p>
              <p className='flex-grow-0 flex-shrink-0 font-semibold text-left text-gray-600 text-2xs'>
                개인정보 처리방침
              </p>
            </div>
            <div className='flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 gap-1 '>
              <p className='flex-grow-0 flex-shrink-0 text-left text-gray-500 text-2xs'>
                로컬링크 | 대표 남윤성, 안상봉
              </p>
              <p className='flex-grow-0 flex-shrink-0 text-left text-gray-500 text-2xs'>
                서울 마포구 백범로 35 베르크만스 우정원 700
              </p>
              <p className='flex-grow-0 flex-shrink-0 text-left text-gray-500 text-2xs'>
                Copyright ⓒ 2023 Localink. All rights reserved.
              </p>
              <p className='flex-grow-0 flex-shrink-0 text-left text-gray-500 text-2xs'>
                사업자등록번호 000-00-0000 | 통신판매업번호 0000-00-0000
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { PropsWithChildren } from 'react';
import Footer from 'src/components/layout/footer';
import Workspace from 'src/components/layout/workspace';

function CommonLayout(props: PropsWithChildren) {
  return (
    <Workspace>
      <main>{props.children}</main>
      <Footer />
      {/* components/modal 참고. 모달렌더리용 포탈 */}
      <div id='modal-root' />
    </Workspace>
  );
}

export default CommonLayout;
